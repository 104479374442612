<template>
  <nav class="peyk_sidenav" v-bind:class="{open :isActiveMenu}">
    <a class="peyk_menu_toggle">
      <div class="peyk_iconmenu" v-bind:class="{change :isActiveMenu}" @click="toggleMenu">
        <div class="bar1"></div>
        <div class="bar2"></div>
        <div class="bar3"></div>
      </div>

      <span class="peyk_menu_span peyk-span_logo">
            <img src="@/assets/images/icons/pass-dash.png" alt="peykdashboard" width="120" class="ms-4">
      </span>
    </a>

    <router-link custom :to="{name: 'NewOrder'}" v-slot="{isActive, href, navigate}">
      <a @click="navigate" :href="href" :class="{active: isActive}">
        <img src="@/assets/images/icons/circle-plus.svg" alt="add" class="peyk_navimage">
        <span class="peyk_menu_span"> New Order</span>
      </a>
    </router-link>
    <router-link custom :to="{name: 'BulkOrder'}" v-slot="{isActive, href, navigate}">
      <a @click="navigate" :href="href" :class="{active: isActive}">
        <img src="@/assets/images/icons/vuesax-linear-box-add.svg" alt="add" class="peyk_navimage">
        <span class="peyk_menu_span"> Bulk Order</span>
      </a>
    </router-link>
    <router-link custom :to="{name: 'Profile'}" v-slot="{isActive, href, navigate}">
      <a @click="navigate" :href="href" :class="{active: isActive}">
        <img src="@/assets/images/icons/user-square.svg" alt="add" class="peyk_navimage">
        <span class="peyk_menu_span">Profile</span>
      </a>
    </router-link>
    <router-link custom :to="{name: 'CurrentOrder'}" v-slot="{isActive, href, navigate}">
      <a @click="navigate" :href="href" :class="{active: isActive}">
        <span class="badge order-badge">{{ currentOrdersCount }}</span>
        <img src="@/assets/images/icons/orders.svg" alt="add" class="peyk_navimage">
        <span class="peyk_menu_span">Orders</span>
      </a>
    </router-link>
    <router-link custom :to="{name: 'Favorites'}" v-slot="{isActive, href, navigate}">
      <a @click="navigate" :href="href" :class="{active: isActive}">
        <img src="@/assets/images/icons/favourites2.svg" alt="add" class="peyk_navimage">
        <span class="peyk_menu_span">Favourites</span>
      </a>
    </router-link>
    <router-link custom :to="{name: 'Payment'}" v-slot="{isActive, href, navigate}">
      <a @click="navigate" :href="href" :class="{active: isActive}">
        <img src="@/assets/images/icons/wallet.svg" alt="add" class="peyk_navimage">
        <span class="peyk_menu_span">Payment</span>
      </a>
    </router-link>
    <!-- <button @click="getOrderReport(user?.user_information?.id)" class="me-3 btn order-report-btn">
                Order Report
              </button> -->
    <div class="peyk_sidenav-reports-style">
      <a class="" @click.prevent="getOrderReport(user?.user_information?.id)">
        <img src="@/assets/images/icons/reports.svg" alt="reports" class="peyk_navimage">
        <!-- <img src="@/assets/images/icons/new-logout.svg" alt="logout" class="peyk_navimage"> -->
        <span class="peyk_menu_span">Reports (Beta Mode)</span>
      </a>
    </div>
    <div class="peyk_sidenav-log-out-style">
      <a class="peyk-dashboard-logout" @click.prevent="logoutModal = true;">
        <img src="@/assets/images/icons/new-logout.svg" alt="logout" class="peyk_navimage">
        <span class="peyk_menu_span">Log out</span>
      </a>
    </div>
    <!-- Modal -->
    <Fade>
      <div class="custom-modal-background" @click.self="logoutModal = false;" v-if="logoutModal">
        <div class="custom-modal">
          <div class="modal-header">
            <span>Log Out</span>
          </div>
          <div class="modal-body">
            <p>Are you sure you want to log out?</p>
          </div>
          <div class="modal-footer">
            <button
                type="button"
                class="request-balance change-color"
                data-bs-dismiss="modal"
                aria-label="Close"
                @click="logout"
            >Yes
            </button>
            <button
                type="button"
                class="request-balance"
                @click.prevent="logoutModal = false;"
            >No
            </button>
          </div>
        </div>
      </div>
    </Fade>

  </nav>

</template>

<script>
import {Fade} from "@/components/transitions";
import {mapActions, mapGetters, mapState} from 'vuex';
import { peyk } from '@/configs';
export default {
  name: "SideMenu",
  components: {
    Fade,
  },
  data() {
    return {
      isActive: false,
      logoutModal: false,
    }
  },
  async created() {
    await this.getCurrentOrders();
  },
  computed:{
    ...mapState(['isActiveMenu']),
    ...mapState('auth', ['user']),
    ...mapGetters('orders', ['currentOrdersCount']),
  },
  methods: {
    ...mapActions('auth', ['logout']),
    ...mapActions(['changeMenuState']),
    ...mapActions('orders', ['getCurrentOrders']),

    goNewOrder() {
      this.$router.push({name: 'NewOrder'});
    },

    goBulkOrder() {
      this.$router.push({name: 'BulkOrder'});
    },

    goCurrentOrder() {
      this.$router.push({name: 'CurrentOrder'});
    },

    goProfile(){
      this.$router.push({name: 'Profile'});
    },

    goFavourites(){
      this.$router.push({name: 'Favorites'});
    },

    goPayments(){
      this.$router.push({name: 'Payment'});
    },

    toggleMenu() {
      this.changeMenuState( ! this.isActiveMenu )
    },

    getOrderReport(id) {
     let base64Encoded= window.btoa( id )
      const url = `${peyk.host}order-report/${base64Encoded}`; 
      window.open(url, '_blank');
    },
  }
}
</script>

<style scoped>
.custom-modal-background{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1055;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2px);
}
.custom-modal{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 26px 33px;
  gap: 24px;
  border: 1px solid #75757521;
  border-radius: 22px 22px 0 0;
  font-family: 'Nunito', sans-serif;
}
.custom-modal > .modal-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #00204A;
  font-weight: bold;
  font-size: 24px;
}
.custom-modal > .modal-body{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  color: #00204A;
  max-height: 320px;
}
.custom-modal > .modal-body > p{
  font-size: 18px;
  font-weight: normal;
  color: #B1B1B1;
}
.custom-modal > .modal-footer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 14px;
}
.custom-modal > .modal-footer > button{
  flex-grow: 1;
  background: #00B7C2;
  border-radius: 5px;
  padding: 14px 0;
  color: #fff;
  font-size: 18px;
  border: 1px solid #70707021;
  outline: none;
}
.custom-modal > .modal-footer > button.change-color{
  background: #00204A;
}
nav.peyk_sidenav {
  background: #00204A;
  border-right: 1px solid #00204A;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  overflow: overlay;
  overflow-x: hidden;
  z-index: 1000;
}

nav.peyk_sidenav a {
  position: relative;
  display: flex;
  flex-direction: row;
  text-decoration: none;
  color: white;
  font-size: 1em;
  align-items: center;
  cursor: pointer;
  width: 70px;
}

nav.open a {
  width: 230px;
}

.peyk_navimage {
  width: 48px;
}

nav.open a:hover, .peyk_sidenav a:focus,  .peyk_sidenav a.active{
  background: #e5f5ff40;
  color: white;
}

.peyk_sidenav img {
  margin: 1rem 1rem 1rem 0.6rem;
}

.peyk_sidenav a span {
  padding: 0;
}

.mouse_out {
  transition: opacity 0.25s,
  padding 0.5s,
  font-size 0.5s;
}

.peyk_sidenav_hover {
  opacity: 1 !important;
  font-size: 1rem !important;
  padding: 0rem 3rem 0px 1rem !important;
}

#hamburger-1.is-active .line:nth-child(2) {
  opacity: 0;
}

#hamburger-1.is-active .line:nth-child(1) {
  -webkit-transform: translateY(13px) rotate(45deg);
  -ms-transform: translateY(13px) rotate(45deg);
  -o-transform: translateY(13px) rotate(45deg);
  transform: translateY(13px) rotate(45deg);
}

#hamburger-1.is-active .line:nth-child(3) {
  -webkit-transform: translateY(-13px) rotate(-45deg);
  -ms-transform: translateY(-13px) rotate(-45deg);
  -o-transform: translateY(-13px) rotate(-45deg);
  transform: translateY(-13px) rotate(-45deg);
}

.peyk-span_logo {
  width: 0rem;
}

.peyk-pashboard-logout {
  align-items: flex-end !important;
  height: 100%;
}

.peyk-pashboard-logout:hover {
  background: #457C9F !important;
}

.bar1, .bar2, .bar3 {
  width: 30px;
  height: 4px;
  background-color: white;
  margin: 7px auto;
  transition: 0.4s;
  border-radius: 1rem;
}

.change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-9px, 6px);
  transform: rotate(-45deg) translate(-9px, 6px);
}

.change .bar2 {
  opacity: 0;
}

.change .bar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  transform: rotate(45deg) translate(-8px, -8px);
}

.peyk_iconmenu {
  margin-left: 1.1rem;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #fbfbfb94;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #fbfbfb94;
}

button.request-balance, button.request-balance:focus {
  background: #21C4F5;
  border: 1px solid #70707021;
  border-radius: 5px;
  color: white;
  padding: 8px 25px;
  outline: none;
}

.modal-footer {
  display: flex;
  justify-content: left;
  border-top: 0px;
}

.modal-content {
  box-shadow: 0px 14px 12px #44444414 !important;
  border: 1px solid #75757521 !important;
  border-radius: 22px;
}

.modal-backdrop {
  z-index: 96;
}

.logout-btn {
  width: 100%;
}

.logout-btn:focus {
  background-color: #457C9F !important;
}

.peyk_sidenav-log-out-style , .peyk_sidenav-reports-style {
  height: 100%;
  display: flex;
  align-items: flex-end;
}

.order-badge {
  opacity: unset !important;
  left: 44px;
  top: 16px;
  background-color: #21c4f5;
  padding: .25rem .28rem !important;
  font-size: 12px !important;
  font-weight: bold;
  position: absolute;
}
</style>
