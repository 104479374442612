import Form from '@/services/form';

import {
  required,
  minLength,
  email,
} from '@vuelidate/validators';


const data = {
  mobile: null,
  email: null,
  business_name: null,
  password: null,
  user_role: 'business',
  occupation:null

}

const rules = {
  mobile: {
    required,
    tel: null,
  },
  email: {
    email,
    required
  },
  business_name: {
    required,
    minLength: minLength(6),
  },
  occupation: {
    required,
  },
  password: {
    required,
    minlength: minLength(6)
  },
}

export default () => new Form(data, rules, 'auth/register');
