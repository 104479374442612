import Form from "@/services/form";

const data = {
    pointOne: null,
    pointTwo: null,
    // vehicle_id: null,
    vehicle_id: 2,  // static vehicle_id for fleet hiding
    };

const rules = {};

export default () => new Form(data, rules, "orders/getOrderDuration");
