<template>
  <div>
    <button type="submit"
      class="btn btn-primary btn-primary-peyk"
      :disabled="form.$invalid || vuelidate.$invalid || isDeliveryFees"
      @click="submit"
      v-bind="$attrs"
    >
      <slot>Submit</slot>
    </button>
  </div>
</template>

<script>
export default {
  name: 'SubmitButton',

  props: {
    form: Object,
    vuelidate: Object,
    canSubmit: {
      type: Boolean,
      default: false,
    },
    isDeliveryFees:{
      type:Boolean,
      default:false,
    }
  },

  methods: {
    async submit() {
      if (this.canSubmit) {
        const res = await this.form.submit();
        this.$emit('submitted', res);
      }
    },
  },
}
</script>

<style>

</style>
