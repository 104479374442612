<template>
  <div class="peyk_bodynav">
    <div class="custom-container">
      <div class="header">
        <span class="text-header">Bulk Order </span>
        <button class="btn btn-primary-peyk d-flex justify-content-center align-items-center"
          @click.stop="downloadTemplate"><span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26" width="36px"
              height="36px">
              <path
                d="M 15 0 L 0 2.875 L 0 23.125 L 15 26 Z M 16 3 L 16 5.96875 L 19.03125 5.96875 L 19.03125 8 L 16 8 L 16 10 L 19 10 L 19 12 L 16 12 L 16 14 L 19 14 L 19 16 L 16 16 L 16 18 L 19 18 L 19 20 L 16 20 L 16 23 L 25.15625 23 C 25.617188 23 26 22.605469 26 22.125 L 26 3.875 C 26 3.394531 25.617188 3 25.15625 3 Z M 20 6 L 24 6 L 24 8 L 20 8 Z M 3.09375 7.9375 L 5.84375 7.9375 L 7.3125 11 C 7.425781 11.238281 7.535156 11.515625 7.625 11.84375 C 7.683594 11.644531 7.8125 11.359375 7.96875 10.96875 L 9.5625 7.9375 L 12.09375 7.9375 L 9.0625 12.96875 L 12.1875 18.09375 L 9.5 18.09375 L 7.75 14.78125 C 7.683594 14.660156 7.601563 14.421875 7.53125 14.09375 L 7.5 14.09375 C 7.46875 14.25 7.402344 14.496094 7.28125 14.8125 L 5.53125 18.09375 L 2.8125 18.09375 L 6.03125 13.03125 Z M 20 10 L 24 10 L 24 12 L 20 12 Z M 20 14 L 24 14 L 24 16 L 20 16 Z M 20 18 L 24 18 L 24 20 L 20 20 Z" />
            </svg></span>Speadsheet Template</button>
        <button class="btn btn-primary-peyk d-flex justify-content-center align-items-center"
          @click.stop="downloadSample"><span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26" width="36px"
              height="36px">
              <path
                d="M 15 0 L 0 2.875 L 0 23.125 L 15 26 Z M 16 3 L 16 5.96875 L 19.03125 5.96875 L 19.03125 8 L 16 8 L 16 10 L 19 10 L 19 12 L 16 12 L 16 14 L 19 14 L 19 16 L 16 16 L 16 18 L 19 18 L 19 20 L 16 20 L 16 23 L 25.15625 23 C 25.617188 23 26 22.605469 26 22.125 L 26 3.875 C 26 3.394531 25.617188 3 25.15625 3 Z M 20 6 L 24 6 L 24 8 L 20 8 Z M 3.09375 7.9375 L 5.84375 7.9375 L 7.3125 11 C 7.425781 11.238281 7.535156 11.515625 7.625 11.84375 C 7.683594 11.644531 7.8125 11.359375 7.96875 10.96875 L 9.5625 7.9375 L 12.09375 7.9375 L 9.0625 12.96875 L 12.1875 18.09375 L 9.5 18.09375 L 7.75 14.78125 C 7.683594 14.660156 7.601563 14.421875 7.53125 14.09375 L 7.5 14.09375 C 7.46875 14.25 7.402344 14.496094 7.28125 14.8125 L 5.53125 18.09375 L 2.8125 18.09375 L 6.03125 13.03125 Z M 20 10 L 24 10 L 24 12 L 20 12 Z M 20 14 L 24 14 L 24 16 L 20 16 Z M 20 18 L 24 18 L 24 20 L 20 20 Z" />
            </svg></span>Speadsheet Sample</button>
      </div>
      <p class="header-subtitle">This section allows you to upload bulk amount of orders to be processed as deliveries
        on the platform. Kindly note that you need to follow the example and template to ensure the data is filled in
        correctly.</p>
      <label for="upload" class="dropbox" @dragenter.prevent.stop @dragover.prevent.stop @drop.prevent="handleDrop"
        v-if="!uploaded">
        <template v-if="!uploading">
          <img src="@/assets/images/icons/vuesax-bulk-document-upload.svg" alt="">
          <div class="upload-text">
            Drag and drop file, Or <span>Browse</span>
          </div>
          <div class="upload-subtitle">
            Support xls, xlsx
          </div>
          <input @change="handleDrop" name="upload" id="upload"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
            type="file" style="display: none;">
        </template>
        <template v-else>
          <div class="spinner-border"></div>
          <span class="process">Processing...</span>
        </template>
      </label>
      <div class="orders-list mb-4" v-else>
        <div class="order-head">
          <span class="order-span">Order Number</span>
          <span class="order-pickup-head">Pickup</span>
          <span>Name</span>
          <span class="order-dropoff">Drop offs</span>
          <span>Name</span>
          <span>Phone number</span>
          <span>Payment type</span>
        </div>
        <div class="order-row" v-for="(order, id) in orders" :key="id">
          <span class="">{{ id }}</span>
          <span class="order-pickup-row">{{ order.pickup.address }}</span>
          <span>{{ order.pickup.name }}</span>
          <span class="order-address"><span v-for="(dropoff, key) in order.dropoffs" :key="key">Drop off {{ key + 1 }}:
              {{
                dropoff.address }}</span></span>
          <span class="order-name"><span class="text-nowrap" v-for="(dropoff, key) in order.dropoffs" :key="key">{{
            dropoff.name }}</span></span>
          <span class="order-phone"><span v-for="(dropoff, key) in order.dropoffs" :key="key">{{ dropoff.phone
              }}</span></span>
          <span>{{ order.payment_type }}</span>
        </div>

        <!-- <div class="footer">
          <button class="btn btn-primary-peyk" :disabled="!uploaded || isLoading" @click="submitOrder">Submit bulk
            order</button>
        </div> -->
      </div>
      <div class="align-items-center d-flex justify-content-end mt-1">
        <button class="btn btn-primary-peyk" :disabled="!uploaded || isLoading || Object.keys(invalidOrders).length" @click="submitOrder">Submit bulk
          order</button>
      </div>
      <div v-if="Object.keys(invalidOrders).length">
        <div class="header-subtitle mb-2">Invalid Orders List:</div>
        <div class="orders-list mb-4">
          <div class="order-head">
            <span class="order-span">Order Number</span>
            <span class="order-pickup-head">Pickup</span>
            <span>Name</span>
            <span class="order-dropoff">Drop offs</span>
            <span>Name</span>
            <span>Phone number</span>
            <span>Payment type</span>
          </div>
          <div class="order-row" v-for="(order, id) in invalidOrders" :key="id">
            <span class="">{{ id }}</span>
            <span class="order-pickup-row">{{ order.pickup.address }}</span>
            <span>{{ order.pickup.name }}</span>
            <span class="order-address"><span v-for="(dropoff, key) in order.dropoffs" :key="key">Drop off {{ key + 1
                }}: {{
                  dropoff.address }}</span></span>
            <span class="order-name"><span class="text-nowrap" v-for="(dropoff, key) in order.dropoffs" :key="key">{{
              dropoff.name }}</span></span>
            <span class="order-phone"><span v-for="(dropoff, key) in order.dropoffs" :key="key">{{ dropoff.phone
                }}</span></span>
            <span>{{ order.payment_type }}</span>
          </div>
        </div>
      </div>

      <div class="backdrop" v-if="submitting"></div>
      <div class="bulk-spinner-container" v-if="submitting">
        <div class="spinner-border"></div>
        <span class="process">Processing...</span>
      </div>
    </div>

  </div>
  <Modal v-model="showErrorsModal">
    <template v-slot:header>
      <span class="head-text">Error</span>
    </template>
    <template v-slot:default>
      <div class="errors-main">
        <div class="errors-head">
          <span>Order<br>number</span>
          <span>Errors</span>
        </div>
        <div class="errors-data" v-for="(error, id) in errors" :key="id">
          <span>{{ id }}</span>
          <span>{{ error }}</span>
        </div>
      </div>
    </template>
  </Modal>
  <Modal v-model="showSuccessModal">
    <template v-slot:header>
      <span class="head-text">Done</span>
    </template>
    <template v-slot:default>
      <div class="success-main">
        <div class="success-content">
          <img src="@/assets/images/icons/vuesax-bulk-clipboard-tick.svg" alt="">
          <span>Your order has been registered,<br> you can see the orders in orders page</span>
        </div>
        <div class="success-footer">
          <button class="btn btn-primary-peyk" @click="redirectToOrders">Orders</button>
        </div>
      </div>
    </template>
  </Modal>
  <a href="" ref="downloadTag" style="display: none;"></a>
</template>

<script>
import Modal from "@/components/BottomModal.vue";
import { orders } from "@/api";
import { mapMutations, mapState } from "vuex";

export default {
  name: "BulkOrder",
  components: {
    Modal,
  },
  data() {
    return {
      showErrorsModal: false,
      showSuccessModal: false,
      dataFile: null,
      uploading: false,
      submitting: false,
      orders: {},
      invalidOrders: {},
      errors: {},
    }
  },
  computed: {
    ...mapState(["isLoading"]),
    uploaded() {
      return Object.keys(this.orders).length && Object.keys(this.errors).length === 0;
    }
  },
  methods: {
    ...mapMutations(['setIsLoading']),
    async downloadTemplate() {
      try {
        const response = await orders.downloadTemplateFile();
        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const downloadTag = this.$refs.downloadTag;
        downloadTag.href = url;
        downloadTag.download = "Pass Template";
        downloadTag.click();
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.log(error);
      }
    },
    async downloadSample() {
      try {
        const response = await orders.downloadSampleFile();
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const downloadTag = this.$refs.downloadTag;
        downloadTag.href = url;
        downloadTag.download = "Pass Template";
        downloadTag.click();
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.log(error);
      }
    },
    async handleDrop(e) {
      this.dataFile = null;
      if (e?.dataTransfer?.files?.length) {
        this.dataFile = e.dataTransfer.files[0];
      }
      if (e?.target?.files?.length) {
        this.dataFile = e.target.files[0];
      }
      if (this.dataFile) {
        const supportedFormats = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel"];
        if (supportedFormats.includes(this.dataFile.type)) {
          const data = new FormData();
          data.append("dataFile", this.dataFile);
          data.append("type", 0);
          try {
            this.uploading = true;
            const result = await orders.uploadExcel(data);
            this.orders = result.data.data.orders;
            this.invalidOrders = result.data.data.invalidOrders;
            //  console.log(result.data.data,10000)
            this.errors = result.data.data.errors;
            if (Object.keys(this.errors).length) {
              this.showErrorsModal = true;
            }
          } catch (e) {
            console.log(e);
          } finally {
            this.uploading = false;
          }
        }
      }
    },
    redirectToOrders() {
      this.$router.push({ name: "CurrentOrder" });
    },
    async submitOrder() {
      try {
        this.submitting = true;
        this.setIsLoading(true);
        const data = new FormData();
        console.log(this.dataFile, "submit")
        data.append("dataFile", this.dataFile);
        data.append("type", 1);
        const result = await orders.uploadExcel(data);
        // const result = await orders.acceptExcel();
        console.log(result);
        this.showSuccessModal = true;
      } catch (e) {
        console.log(e);
      } finally {
        this.submitting = false;
        this.setIsLoading(false);
      }
    }
  },
}
</script>

<style>
.custom-container {
  margin-left: 159px;
  margin-right: 32px;
}

.header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 80px;
}

.header>button {
  font-family: Nunito, sans-serif;
  font-weight: 300;
  font-size: 24px;
}

.header>button:nth-child(2) {
  margin-left: auto;
  margin-right: 15px;
}

.header>button:nth-child(2)>span>svg {
  fill: #00204A;
}

.header>button:last-child>span>svg {
  fill: #F2DDC8;
}

.header>.text-header {
  font-family: Bariol, sans-serif;
  font-size: 48px;
  color: #00204A;
}

.header>button>span {
  margin-right: 14px;
}

.header-subtitle {
  font-family: Bariol, sans-serif;
  font-size: 18px;
  color: #00204A;
  margin-top: 38px;
}

.spinner-border {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}

.process {
  font-family: Bariol, sans-serif;
  font-size: 18px;
}

.orders-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  /* max-height: 680px; */
  max-height: 300px;
  overflow-y: auto;
  color: #00204A;
}

.orders-list>* {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* min-height: 60px; */
  column-gap: 35px;
  padding: 0 23px;
}

.orders-list>*:nth-child(odd) {
  background: rgba(0, 183, 194, 0.17);
}

.order-head>*,
.order-row>* {
  flex: 1;
}

.order-head>*:first-child,
.order-row>*:first-child {
  text-align: center;
}

.orders-list>.order-head>.order-pickup-head,
.orders-list>.order-head>.order-dropoff {
  flex-basis: 14%;
  /* flex-basis: 10%; */
}

.orders-list>.order-row>.order-address,
.orders-list>.order-row>.order-pickup-row {
  flex-basis: 14%;
  /* flex-basis: 10%; */
}

.orders-list>.order-row>.order-address,
.orders-list>.order-row>.order-phone,
.orders-list>.order-row>.order-name {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: stretch;
  gap: 10px;
}

.dropbox {
  width: 100%;
  height: 320px;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #F6F6F687 0 0 no-repeat padding-box;
  border: 2px dashed #00204A3D;
  border-radius: 5px;
}

.dropbox>.upload-text {
  margin-top: 35px;
  font-family: Bariol, sans-serif;
  font-size: 28px;
  color: #00204A;
}

.dropbox>.upload-text>span {
  color: #00B7C2;
}

.dropbox>.upload-subtitle {
  margin-top: 9px;
  font-family: Bariol, sans-serif;
  font-size: 22px;
  color: #BCBCBC;
}

.orders-list::-webkit-scrollbar {
  width: 10px;
  /* Width of the scrollbar */
}

.orders-list::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* Background of the scrollbar track */
}

.orders-list::-webkit-scrollbar-thumb {
  background: #888;
  /* Color of the scrollbar handle */
  border-radius: 10px;
  /* Rounded corners */
}

.orders-list::-webkit-scrollbar-thumb:hover {
  background: #555;
  /* Color of the scrollbar handle on hover */
}

.footer {
  /* position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  bottom: 34px;
  right: 32px; */
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  bottom: 3px;
  right: 32px;
}

.footer>button:disabled {
  background: #BCBCBC 0 0 no-repeat padding-box !important;
}

.footer>button {
  width: 423px;
  font-family: Nunito, sans-serif;
  font-weight: normal;
  font-size: 24px;
  color: #F6F6F6;
}

.head-text {
  font-family: Bariol, sans-serif;
  font-size: 26px;
  font-weight: bold;
  color: #00204A;
  opacity: 1;
}

.errors-main {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  max-height: 500px;
  overflow-y: auto;
}

.errors-main>* {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 60px;
  column-gap: 35px;
  padding: 0 23px;
}

.errors-main>.errors-head {
  background: rgba(0, 183, 194, 0.17);
}

.errors-main>.errors-head>span:first-child {
  color: #00204A;
}

.errors-main>*>span {
  font-family: Bariol, sans-serif;
  font-weight: normal;
  font-size: 16px;
}

.errors-main>*:not(.errors-head)>span:first-child {
  width: 49px;
  text-align: center;
  color: #00B7C2;
}

.errors-main>*>span:last-child {
  color: #FE4747;
}

.backdrop {
  position: fixed;
  /* Use fixed positioning for the backdrop */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  /* Semi-transparent black */
  backdrop-filter: blur(4px);
  /* Apply blur effect */
  z-index: 999;
  /* Ensure it's below the card but above other content */
}

.bulk-spinner-container {
  position: fixed;
  /* Change to fixed positioning to keep it centered */
  background: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px 15px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  /* Soft shadow */
  border-radius: 8px;
  /* Rounded corners */
  z-index: 1000;
  /* Ensure it's above the backdrop */
}

.success-main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}

.success-main>* {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 23px;
}

.success-main>.success-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 24px;
}

.success-main>*:last-child {
  padding-bottom: 27px;
}

.success-main>.success-footer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.success-main>.success-footer>button {
  width: 100%;
}
</style>