import { cards } from '@/api';

const state = () => ({
  cards: [],
  balance:  0,
  credit:  0,
  balanceList :[],
  addCredit : "",
});

const mutations = {
  setCards(state, payload) {
    state.cards = [...payload];
  },

  addCard(state, payload) {
    const {cards} = state;

    cards.unshift(payload);
    state.cards = [...cards];

  },

  removeCard(state, payload) {
    state.cards = [...state.cards.filter(card => card.id != payload)];
  },

  setBalance (state , payload){
    state.balance = payload
  },
  setCredit (state , payload){
    state.credit = payload
  },

  setBalanceList(state , payload){
    state.balanceList = payload;
  },

  addCredit(state, payload){
    state.addCredit = payload;
  },
}

const actions = {
  async getCards({commit}) {
    const {data} = await cards.getCards();
    commit('setCards', data.data.result);
  },

  async addCard({commit}, payload) {
    commit('setIsLoading', true, { root: true });
    const {data} = await cards.addStripeCard(payload);
    commit('setIsLoading', false, { root: true });


    if (Object.keys(data.data).length) {
      commit('addCard', data.data);
    }
  },

  async removeCard({commit}, payload) {
    await cards.removeCard({card_id: payload});
    commit('removeCard', payload);
  },

  async getBalance({commit}) {
    const { data } = await cards.getBalance();

    commit('setBalance' , data.data.balance);
    commit('setCredit' , data.data.credit);
  },

  async getBalanceList({commit} , payload){
    const  { data} = await cards.getBalanceList(payload);
    commit('setBalanceList' ,data.data.result);
  },

  async addCreditAction({ commit } , payload) {
    const { data } = await cards.addCredit(payload);
    commit('addCredit', data.data.payment_link);
    if (this.addCredit == '') {
      this.addCredit =  data.data.payment_link;
    }
  },

}

const getters = {
  balance: (state) => state.cards.balance ||  0,
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
